import { SidebarItemsType } from "../../types/sidebar";

import {
  BookOpen,
  Briefcase,
  Calendar,
  CheckSquare,
  CreditCard,
  Grid,
  Heart,
  Layout,
  List,
  Map,
  ShoppingCart,
  PieChart,
  Sliders,
  Users,
  AlertCircle,
} from "react-feather";

import {
  PersonOutline,
  BarChart,
  Home,
  VpnKey,
  ExitToApp,
  Settings,
  ChatBubbleOutline,
  Notifications,
} from "@material-ui/icons";

const adminSection = [
  {
    href: "/dashboard",
    title: "Dashboard",
    icon: BarChart,
  },
  {
    href: "/users/dashboard",
    title: "Admin Control",
    icon: VpnKey,
  },
] as unknown as SidebarItemsType[];

const leelouSection = [
 
  {
    href: "/users/leelou",
    title: "Leelou Users",
    icon: PersonOutline,
  },
  {
    href: "/sos",
    title: "SOS Alerts",
    icon: AlertCircle,
  },
  {
    href: "/notificationcenter",
    title: "Notification Center",
    icon: Notifications,
  },

  {
    href: "/preregistration",
    title: "Pre-Registration",
    icon: List,
  },
  {
    href: "/feedback",
    title: "User Feedbacks",
    icon: ChatBubbleOutline,
  },
] as SidebarItemsType[];

const elementsSection = [
  {
    href: "/components",
    icon: Grid,
    title: "Components",
    children: [
      {
        href: "/components/alerts",
        title: "Alerts",
      },
      {
        href: "/components/accordion",
        title: "Accordion",
      },
      {
        href: "/components/avatars",
        title: "Avatars",
      },
      {
        href: "/components/badges",
        title: "Badges",
      },
      {
        href: "/components/buttons",
        title: "Buttons",
      },
      {
        href: "/components/cards",
        title: "Cards",
      },
      {
        href: "/components/chips",
        title: "Chips",
      },
      {
        href: "/components/dialogs",
        title: "Dialogs",
      },
      {
        href: "/components/lists",
        title: "Lists",
      },
      {
        href: "/components/menus",
        title: "Menus",
      },
      {
        href: "/components/pagination",
        title: "Pagination",
      },
      {
        href: "/components/progress",
        title: "Progress",
      },
      {
        href: "/components/snackbars",
        title: "Snackbars",
      },
      {
        href: "/components/tooltips",
        title: "Tooltips",
      },
    ],
  },
  {
    href: "/charts",
    icon: PieChart,
    title: "Charts",
  },
  {
    href: "/forms",
    icon: CheckSquare,
    title: "Forms",
    children: [
      {
        href: "/forms/pickers",
        title: "Pickers",
      },
      {
        href: "/forms/selection-controls",
        title: "Selection Controls",
      },
      {
        href: "/forms/selects",
        title: "Selects",
      },
      {
        href: "/forms/text-fields",
        title: "Text Fields",
      },
      {
        href: "/forms/editors",
        title: "Editors",
      },
      {
        href: "/forms/formik",
        title: "Formik",
      },
    ],
  },
  {
    href: "/tables",
    icon: List,
    title: "Tables",
    children: [
      {
        href: "/tables/simple-table",
        title: "Simple Table",
      },
      {
        href: "/tables/advanced-table",
        title: "Advanced Table",
      },
      {
        href: "/tables/data-grid",
        title: "Data Grid",
      },
    ],
  },
  {
    href: "/icons",
    icon: Heart,
    title: "Icons",
    children: [
      {
        href: "/icons/material-icons",
        title: "Material Icons",
      },
      {
        href: "/icons/feather-icons",
        title: "Feather Icons",
      },
    ],
  },
  {
    href: "/maps",
    icon: Map,
    title: "Maps",
    children: [
      {
        href: "/maps/google-maps",
        title: "Google Maps",
      },
      {
        href: "/maps/vector-maps",
        title: "Vector Maps",
      },
    ],
  },
] as SidebarItemsType[];

const docsSection = [
  {
    href: "/documentation/welcome",
    icon: BookOpen,
    title: "Documentation",
  },
  {
    href: "/changelog",
    icon: List,
    title: "Changelog",
    badge: "v3.0.0",
  },
] as SidebarItemsType[];

const navItems = [
  {
    title: "Admin",
    pages: adminSection,
  },
  {
    title: "Leelou",
    pages: leelouSection,
  },
  //{
  //title: "Elements",
  //pages: elementsSection,
  //},
  //{
  //title: "Material App",
  //pages: docsSection,
  //},
];

export default navItems;
